<script setup lang="ts">
import type { GroupInList } from '@/types/group';
import type { FollowingGroup } from '@/types/profile';

interface CombinedGroupInfo extends GroupInList {
	isFollowed: boolean;
}

const props = defineProps({
	userId: {
		type: Number as PropType<number | null>,
		default: null,
	},
	profileId: {
		type: Number as PropType<number | null>,
		default: null,
	},
	isPrivateView: {
		type: Boolean,
		default: false,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onOpenGroupCreationDlg'): void
	(e: 'onOpenAuthenticationDlg'): void
	(e: 'onOpenMessageDlg', value: { id: number, followerNum: number }): void
	(e: 'onUpdateFollowingStatus'): void
}>();

const profileStore = useProfileStore();
const groupStore = useGroupStore();
const authStore = useAuthStore();
const { isMobile } = useWindowResize();

const currentPage = ref(1);
const items = ref<CombinedGroupInfo[]>([]);

const computedProfileId = computed({
	get: () => props.profileId,
	set: (value) => Number(value),
});

const computedUserId = computed({
	get: () => props.userId,
	set: (value) => Number(value),
});

const fetchId = computed(() => (props.isPrivateView ? Number(props.profileId) : Number(props.userId)));
const totalPages = computed(() => groupStore.detailedGroups.paginationInfo?.totalPages);
const isLoading = computed(() => groupStore.isLoading || props.isLoading);

function getSharedGroupUrl(id: number) {
	return getFullUrlPath(`/group/${id}`);
}

async function fetchFollowingStatus() {
	if (!authStore.isLoggedIn || !computedProfileId.value || !computedUserId.value) {
		return;
	}

	const groupTotalItems = groupStore.detailedGroups.paginationInfo?.totalItems;
	await profileStore.getFollowingList(computedProfileId.value, {
		organizer: computedUserId.value,
		currentPage: currentPage.value,
		itemPerPage: groupTotalItems,
		sortBy: FOLLOWING_SORTING_METHOD.OLDEST_FOLLOWED,
	});
}

async function fetchGroupList() {
	if (fetchId.value) {
		await groupStore.getGroupInfoList(fetchId.value, {
			currentPage: currentPage.value,
			itemPerPage: isMobile.value ? 3 : 6,
			sortBy: GROUP_SORTING.OLDEST_ID,
		});
	}
}

async function fetchData() {
	await fetchGroupList();

	if (!props.isPrivateView) {
		await fetchFollowingStatus();
	}
}

function handlePageChange(page: number) {
	currentPage.value = page;
	fetchData();
}

function transformGroupListCombinedData(groupInfoList: GroupInList[], groupFollowingInfoList: FollowingGroup[]): CombinedGroupInfo[] {
	if (!groupInfoList.length) {
		return [];
	}

	return groupInfoList.map((item) => {
		const isFollowed = groupFollowingInfoList?.find((group) => group.id === item.id);
		return {
			...item,
			isFollowed: !!isFollowed,
		};
	});
}

async function handleUpdateFollowingStatus() {
	await fetchData();
	emit('onUpdateFollowingStatus');
}

onMounted(() => {
	fetchData();
});

watch(() => props.profileId, (newValue) => {
	if (newValue) {
		// When sign in and got redirect to profile page,
		// Re-fatch data again after receive profile ID
		fetchData();
	}
});

watch(() => [groupStore.detailedGroups.data, profileStore.followingList], ([newList, newFollowingList]) => {
	// @ts-ignore
	items.value = transformGroupListCombinedData(newList, newFollowingList);
});

defineExpose({
	fetchGroupList: fetchData,
});
</script>

<template>
  <div class="group-list">
    <div
      v-if="isPrivateView"
      class="header"
    >
      <h1 class="text-lg text-semibold">
        Groups
      </h1>
      <BaseButton
        @click="$emit('onOpenGroupCreationDlg')"
      >
        <PlusIcon
          width="20"
          height="20"
        />
        <p class="text-sm text-semibold">
          Create group
        </p>
      </BaseButton>
    </div>
    <template v-if="items?.length">
      <div class="items">
        <GroupDetailsItem
          v-for="item in items"
          :key="item.id"
          :data="item"
          :is-private-view="isPrivateView"
        >
          <template #miscBtn>
            <div class="misc-btn-container">
              <template v-if="isPrivateView">
                <BaseButton
                  variant="subtle"
                  color="gray"
                  size="sm"
                  :disabled="isLoading"
                  @click="$emit('onOpenMessageDlg', { id: item.id, followerNum: item.followerNumber })"
                >
                  <template #leftIcon>
                    <MailIcon />
                  </template>
                </BaseButton>
              </template>
              <template v-else>
                <FollowButton
                  ref="followBtnRef"
                  :user-id="computedProfileId"
                  :group-id="item.id"
                  :role="item.isFollowed ? GROUP_ROLE.MEMBER : null"
                  :is-loading="isLoading"
                  :is-logged-in="authStore.isLoggedIn"
                  :is-small-size="!isPrivateView"
                  @on-update="handleUpdateFollowingStatus"
                  @on-sign-in="$emit('onOpenAuthenticationDlg')"
                />
              </template>
              <ShareButton
                v-if="item.id"
                :url="getSharedGroupUrl(item.id)"
                :group-id="item.id"
                :variant="BtnVariant[2]"
              />
            </div>
          </template>

          <template #actionBtn>
            <RouterLink
              :to="{
                name: ROUTE.GROUP.NAME,
                params: {
                  id: item.id,
                }
              }"
              target="_blank"
            >
              <BaseButton>
                <p class="text-sm text-semibold">
                  View group
                </p>
              </BaseButton>
            </RouterLink>
            <RouterLink
              v-if="isPrivateView"
              :to="{
                name: ROUTE.EVENT_CREATE.NAME,
                query: {
                  groupId: item.id,
                }
              }"
              target="_blank"
            >
              <BaseButton
                variant="outlined"
                color="gray"
              >
                <p class="text-sm text-semibold">
                  Create event
                </p>
              </BaseButton>
            </RouterLink>
          </template>
        </GroupDetailsItem>
      </div>
      <div class="paginate">
        <BasePagination
          :page="currentPage"
          :total-pages="totalPages"
          @on-page-change="handlePageChange"
        />
      </div>
    </template>
    <template v-else>
      <EventListLoader
        v-if="isLoading"
        button-width="105"
      />
      <NoDataFound v-else>
        <template #content>
          <h1 class="text-md text-semibold">
            No events found
          </h1>
          <p class="text-sm text-regular">
            Your events will be listed here once you create an event.
          </p>
        </template>
        <template #action>
          <RouterLink
            class="w-full"
            :to="{
              name: ROUTE.EVENT_CREATE.NAME,
            }"
          >
            <BaseButton>Create event</BaseButton>
          </RouterLink>
        </template>
      </NoDataFound>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.group-list {
  margin-top: spacings-get(4);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: spacings-get(6);
  }

  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: spacings-get(12);
    column-gap: spacings-get(6);
    padding: 0 rem(2);

    @include media-query-max(mobile) {
      grid-template-columns: repeat(1, 1fr);
      gap: spacings-get(4);
    }

    .misc-btn-container {
      button {
        padding: spacings-get(2);
      }
    }
  }
}
</style>