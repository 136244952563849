export const ROUTE = {
	INDEX: {
		NAME: 'index',
		PATH: '/',
		TITLE: 'Home',
	},
	SIGN_IN: {
		NAME: 'signin',
		PATH: '/signin',
		TITLE: 'Sign in',
	},
	SIGN_UP: {
		NAME: 'signup',
		PATH: '/signup',
		TITLE: 'Register',
	},
	SIGN_OUT: {
		NAME: 'signout',
		PATH: '/signout',
		TITLE: 'Sign Out',
	},
	EVENT: {
		NAME: 'event',
		PATH: '/event',
		TITLE: 'Event',
	},
	EVENT_VIEW: {
		NAME: 'event-id',
		PATH: '/event/:id',
		TITLE: '{0}',
	},
	EVENT_CREATE: {
		NAME: 'event-create',
		PATH: '/event/create',
		TITLE: 'Create event',
	},
	EVENT_EDIT: {
		NAME: 'event-id-edit',
		PATH: '/event/:id/edit',
		TITLE: 'Edit {0}',
	},
	EVENT_ATTENDEES: {
		NAME: 'event-id-attendees',
		PATH: '/event/:id/attendees',
		TITLE: 'Attendees - {0}',
	},
	EVENT_SALE_ANALYTICS: {
		NAME: 'event-id-sales-analytics',
		PATH: '/event/:id/sales-analytics',
		TITLE: 'Sales & Analytics - {0}',
	},
	EVENT_QUESTIONNAIRE: {
		NAME: 'event-id-questionnaire',
		PATH: '/event/:id/questionnaire',
		TITLE: 'Questionnaire - {0}',
	},
	EVENT_CHECK_IN: {
		NAME: 'event-id-check-in',
		PATH: '/event/:id/check-in',
		TITLE: 'QR Check in - {0}',
	},
	EVENT_PREVIEW: {
		NAME: 'event-id-preview',
		PATH: '/event/:id/preview',
		TITLE: '{0} Preview',
	},
	SEARCH: {
		NAME: 'search',
		PATH: '/search',
		TITLE: 'Explore events {0}',
	},
	CANCELLATION_POLICY: {
		NAME: 'cancellation-policy',
		PATH: '/cancellation-policy',
		TITLE: 'Cancellation Policy',
	},
	PRICING: {
		NAME: 'pricing',
		PATH: '/pricing',
		TITLE: 'Pricing',
	},
	ABOUT_US: {
		NAME: 'about-us',
		PATH: '/about-us',
		TITLE: 'About us',
	},
	PRICING_CALCULATOR: {
		NAME: 'pricing-calculator',
		PATH: '/pricing/calculator',
		TITLE: 'Pricing calculator',
	},
	USER: {
		NAME: 'user-id',
		PATH: '/user/:id',
		TITLE: '{0}',
	},
	GROUP: {
		NAME: 'group-id',
		PATH: '/group/:id',
		TITLE: '{0}',
	},
	PROFILE: {
		NAME: 'profile',
		PATH: '/profile',
		TITLE: 'Profile',
	},
	PROFILE_ATTENDING: {
		NAME: 'profile-attending',
		PATH: '/profile',
		TITLE: 'Profile',
	},
	PROFILE_HOSTING: {
		NAME: 'profile-hosting',
		PATH: '/profile',
		TITLE: 'Profile',
	},
	SETTINGS: {
		NAME: 'settings',
		PATH: '/settings',
		TITLE: 'Account settings',
	},
	SETTINGS_PAYOUTS: {
		NAME: 'settings-payouts',
		PATH: '/settings',
		TITLE: 'Payouts',
	},
	SETTINGS_SAVED_CARD: {
		NAME: 'settings-saved-card',
		PATH: '/settings',
		TITLE: 'Saved cards',
	},
	HELP: {
		NAME: 'help',
		PATH: '/help',
		TITLE: 'Help',
	},
	ERROR_404: {
		NAME: 'not-found',
		PATH: '/not-found',
		TITLE: '404 Not Found',
	},
	ERROR_401: {
		NAME: 'unauthorized',
		PATH: '/unauthorized',
		TITLE: '401 Unauthorized',
	},
	RECEIPT: {
		NAME: 'receipt-id',
		PATH: '/receipt/:id',
		TITLE: 'Receipt {0}',
	},
	TERMS: {
		NAME: 'terms',
		PATH: '/terms',
		TITLE: 'Terms',
	},
	PRIVACY_POLICY: {
		NAME: 'privacy-policy',
		PATH: '/privacy-policy',
		TITLE: 'Privacy Policy',
	},
	COOKIE_POLICY: {
		NAME: 'cookie-policy',
		PATH: '/cookie-policy',
		TITLE: 'Cookie Policy',
	},
	COMMUNITY_GUIDELINES: {
		NAME: 'community-guidelines',
		PATH: '/community-guidelines',
		TITLE: 'Community Guidelines',
	},
};