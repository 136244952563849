export enum GROUP_TYPE {
	PEASY_GROUP = 50,
}

export enum GROUP_ROLE {
	MEMBER = 'member',
	FOLLOWER = 'follower',
	ADMIN = 'admin',
	OWNER = 'owner',
}

export enum GROUP_IMAGE_FIELD {
	COVER_IMAGE = 'coverImage',
	LOGO = 'logo',
}

export enum GROUP_SORTING {
	NEWEST_CREATED = 'desc(pod.created)',
	OLDEST_CREATED = 'asc(pod.created)',
	NEWEST_ID = 'desc(pod.id)',
	OLDEST_ID = 'asc(pod.id)',
}