import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import loadImage from 'blueimp-load-image';
import VisaCardIcon from '@/components/icons/VisaCardIcon.vue';
import MasterCardIcon from '@/components/icons/MasterCardIcon.vue';
import AmexCardIcon from '@/components/icons/AmexCardIcon.vue';
import DiscoverCardIcon from '@/components/icons/DiscoverCardIcon.vue';
import DinnerClubCardIcon from '@/components/icons/DinnerClubCardIcon.vue';
import JCBCardIcon from '@/components/icons/JCBCardIcon.vue';
import UnionPayCardIcon from '@/components/icons/UnionPayCardIcon.vue';
import type { RouteRecordNameGeneric } from 'vue-router';

const REDIRECT_URL = import.meta.env.VITE_LEGACY_PEATIX_URL;
const PEATIX_TOKEN = import.meta.env.VITE_PEATIX_TOKEN;

export const getLocalStorage = (key: string) => {
	const stringValue = localStorage.getItem(key) || '';
	try {
		return JSON.parse(stringValue);
	} catch {
		return stringValue;
	}
};

export const setLocalStorage = (key: string, data: string) => {
	localStorage.setItem(key, data);
};

export const removeLocalStorage = (key: string) => {
	localStorage.removeItem(key);
};

export const getSessionStorage = (key: string) => {
	const stringValue = sessionStorage.getItem(key) || '';
	try {
		return JSON.parse(stringValue);
	} catch {
		return stringValue;
	}
};

export const setSessionStorage = (key: string, data: string) => {
	sessionStorage.setItem(key, data);
};

export const removeSessionStorage = (key: string) => {
	sessionStorage.removeItem(key);
};

export const deepClone = (obj: any) => {
	return JSON.parse(JSON.stringify(obj));
};

export const generateRandomColor = (index: number) => {
	const baseSeed = 1000; // Adjust as needed for more randomness

	// Use the index combined with a base seed for added randomness
	let seed: number = index * baseSeed;

	// Use a simple random number generator to introduce more variability
	const random = () => (Math.sin(seed++) + 1) / 2;

	// Generate random values for the red, green, and blue components with a wider range
	const red = Math.floor(random() * 200) + 55;
	const green = Math.floor(random() * 200) + 55;
	const blue = Math.floor(random() * 200) + 55;

	// Convert the RGB components to a CSS color code
	const colorCode = `${red},${green},${blue}`;

	return colorCode;
};

export const fetchImageAsBlob = async (imagePath: string) => {
	const response = await fetch(imagePath);
	const blob = await response.blob();
	return blob;
};

export const openLinkInCurrentTab = (url: string) => {
	// Redirect to the specified URL
	window.location.href = url;
};

export const openLink = (url: string, target = '_blank') => {
	// Redirect to the specified URL
	window.open(url, target);
};

export const copyToClipboard = async (text: string) => {
	return navigator.clipboard.writeText(text);
};

export const getFullUrlPath = (path: string) => {
	return `${window.location.origin}${import.meta.env.VITE_SUBDOMAIN}${path}`;
};

/**
 * Converts a snake_case or camelCase string to title case.
 *
 * @param {string} str - The input string to be converted.
 * @returns {string} The converted string in title case.
 * @example
 *
 * convertToTitleCase('currentlyDue');
 * // Returns: "Currently Due"
 *
 * convertToTitleCase('business_profile');
 * // Returns: "Business Profile"
 *
 * convertToTitleCase('support_phone');
 * // Returns: "Support Phone"
 */
export const convertToCapitalCase = (str: string) => {
	return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

export const isCardExpired = (expiryDate: string): boolean => {
	const currentDateTime = dayjs();
	const expiryDateTime = dayjs(expiryDate, 'MM/YYYY');

	return expiryDateTime.isBefore(currentDateTime, 'month');
};

export const getCreditCardIcon = (brand: string | undefined) => {
	switch (brand) {
		case CARD_BRAND.VISA:
			return VisaCardIcon;
		case CARD_BRAND.MASTERCARD:
			return MasterCardIcon;
		case CARD_BRAND.AMEX:
			return AmexCardIcon;
		case CARD_BRAND.DISCOVER:
			return DiscoverCardIcon;
		case CARD_BRAND.DINERS:
			return DinnerClubCardIcon;
		case CARD_BRAND.JCB:
			return JCBCardIcon;
		case CARD_BRAND.UNIONPAY:
			return UnionPayCardIcon;
		default:
			return VisaCardIcon;
	}
};

export const getSortDirection = (direction: string) => {
	switch (direction) {
		case TABLE_SORT_DIRECTION.ASC:
			return 'down';
		case TABLE_SORT_DIRECTION.DESC:
			return 'up';
		default:
			return '';
	}
};

export const getDefaultUser = (data: AppleSignInAPI.SignInResponseI) => {
	const decoded: { email: string } = jwtDecode(data.authorization.id_token);
	return {
		email: data.user?.email || decoded.email,
		name: {
			firstName: data.user?.name.firstName || 'Firstname',
			lastName: data.user?.name.lastName || 'Lastname',
		},
	};
};

export const switchToPeatix = () => {
	const userAccessToken = getLocalStorage('access_token');
	openLink(`${REDIRECT_URL}/auth-session-transfer?token=${userAccessToken}-${PEATIX_TOKEN}`, '_self');
	removeLocalStorage(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
	removeLocalStorage(LOCAL_STORAGE_ITEMS.USER_SECRET);
	removeLocalStorage(LOCAL_STORAGE_ITEMS.USER);
};

export const replaceTextInCurlyBraces = <T>(text: string, replacementValues: T[]) => {
	return text.replace(REGEX_MATCH_CURLY_BRACES_PATTERN, (_, replacedText) => replacementValues[replacedText] || replacedText);
};

export const getRouteTitleByName = (name: RouteRecordNameGeneric) => {
	const routes = Object.values(ROUTE);
	const title = 'Peatix';

	if (name) {
		const currentRoute = routes.find((route) => route.NAME === name);
		return currentRoute ? `${currentRoute.TITLE} | ${title}` : title;
	}
	return title;
};

export const getImageDimension = (imageUrl: string): Promise<{
	width: number,
	height: number
}> => {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = imageUrl;
		img.onload = () => {
			resolve({
				width: img.width,
				height: img.height,
			});
		};
	});
};

/**
 * Use https://github.com/blueimp/JavaScript-Load-Image to fix heif file rotate
 *
 * @param file File
 * @returns Promise<File | Blob>
 */
export const processImageFile = async (file: File, maxWidth: number): Promise<File | Blob> => {
	return new Promise((resolve) => {
		loadImage(
			file,
			(img, data) => {
				if (data && data.imageHead && data.exif) {
					// Rotate the file by override the Exif Orientation value
					loadImage.writeExifData(data.imageHead, data as loadImage.WriteExifData, 'Orientation', 1);
					const newImageFile = img as HTMLCanvasElement;
					// Convert canvas to Blob
					newImageFile.toBlob((blob) => {
						if (blob && data.imageHead) {
							loadImage.replaceHead(blob, data.imageHead, (newBlob) => {
								if (newBlob) {
									resolve(newBlob);
								}
							});
						}
					}, 'image/jpeg');
				} else {
					resolve(file);
				}
			},
			{
				meta: true,
				orientation: true,
				canvas: true,
				maxWidth,
			},
		);
	});
};

export const convertImageUrlToBlob = async (url: string) => {
	return await fetch(url).then((r) => r.blob());
};

export const getQueryParam = (key: string): string | null => {
	try {
		const url = new URL(window.location.href);
		return url.searchParams.get(key);
	} catch (error) {
		return null;
	}
};

export const getPluralSuffix = (num: number) => {
	return num > 1 ? 's' : '';
};