import type { LocationCoordinate } from '@/types/location';

export function useGeocoder() {
	const geocoder = new google.maps.Geocoder();

	async function fetchLocation(
		places?: google.maps.places.PlaceResult,
		coordinates?: LocationCoordinate | null,
	) {
		const request = {
			...(places?.formatted_address ? { address: places.formatted_address } : {}),
			...(coordinates?.lat ? {
				location: {
					lat: coordinates.lat ?? 0,
					lng: coordinates.lng ?? 0,
				},
			} : {}),
			language: 'en',
			region: 'US',
		};
		const { results } = await geocoder.geocode(request);

		let cityName = '';
		// Use the first index to find the country code of the selected location
		const firstIndex = 0;
		// Select the second to last index for the best city name result
		const secondToLastIndex = results.length - 2;

		// Find the most specific city name first in order to display on the input
		const cityTypes = ['postal_town', 'locality', 'administrative_area_level_1', 'administrative_area_level_2'];
		for (const cityType of cityTypes) {
			const addressComponent = results?.[secondToLastIndex]?.address_components?.find((component) => component.types.includes(cityType));
			if (addressComponent) {
				cityName = addressComponent.long_name || '';
				break;
			}
		}
		const countryName = results?.[secondToLastIndex]?.address_components?.find((component) => component.types.includes('country'))?.long_name ?? '';

		// Find the country code
		const addressComponents = results?.[firstIndex].address_components;
		const countryCode = addressComponents?.find((component) => component.types.includes('country'))?.short_name || '';

		return {
			countryCode,
			countryName,
			cityName,
		};
	}

	return {
		fetchLocation,
	};
}