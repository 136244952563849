<script setup lang="ts">
defineProps({
	loading: {
		type: Boolean,
		default: false,
	},
	rows: {
		type: String,
		default: '5',
	},
	buttonWidth: {
		type: String,
		default: '136',
	},
	hideButton: {
		type: Boolean,
		default: false,
	},
	hideBadge: {
		type: Boolean,
		default: false,
	},
});

const { isMobile } = useWindowResize();

</script>
<template>
  <MultipleSkeletonLoader
    class="loader-event-container"
    :rows="rows"
    has-divider
  >
    <div class="loader-event-item">
      <ImageSkeletonLoader :height="isMobile ? `172` : `80`" />
      <div class="loader-event-content">
        <ParagraphSkeletonLoader :width="isMobile ? `` : `80%`" />
        <div
          v-if="!hideBadge"
          class="loader-event-badge"
        >
          <ButtonSkeletonLoader
            width="40"
            height="20"
          />
          <ButtonSkeletonLoader
            width="40"
            height="20"
          />
          <ButtonSkeletonLoader
            width="40"
            height="20"
          />
        </div>
      </div>
      <ButtonSkeletonLoader
        v-if="!hideButton"
        class="action"
        :width="isMobile ? `100%` : buttonWidth"
      />
    </div>
  </MultipleSkeletonLoader>
</template>
<style scoped lang="scss">
.loader-event-container {
  @include media-query-max(mobile) {
    padding: spacings-get(4) 0;
  }

  .loader-event-item {
    display: grid;
    grid-template-columns: rem(160) auto rem(136);
    gap: spacings-get(4);
    align-items: start;
    padding: spacings-get(6) 0;

    @include media-query-max(mobile) {
      grid-template-columns: 1fr;
      padding: 0 0 spacings-get(4);
    }

    .action {
      margin: auto;
    }

    .loader-event-content {
      display: flex;
      flex-direction: column;
      gap: spacings-get(2);

      :deep(.v-skeleton-loader__paragraph) {
        gap: spacings-get(2);
      }

      .loader-event-badge {
        display: flex;
        justify-content: flex-start;
        gap: spacings-get(2);

        :deep(.v-skeleton-loader__button) {
          border-radius: rem(10);
        }
      }
    }
  }
}
</style>