<script setup lang="ts">
import profileCoverPlaceholder from '@/assets/images/profile-cover-placeholder.jpg';
import avatarPlaceholder from '@/assets/svg/user-circle.svg';

const props = defineProps({
	coverImageUrl: {
		type: String,
		default: '',
	},
	profileImageUrl: {
		type: String,
		default: '',
	},
	name: {
		type: String,
		default: '',
		required: true,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	isGroupProfile: {
		type: Boolean,
		default: false,
	},
});

const { isMobile } = useWindowResize();

const coverImage = computed(() => {
	return props.coverImageUrl ? props.coverImageUrl : profileCoverPlaceholder;
});

const avatarImage = computed(() => {
	return props.profileImageUrl ? props.profileImageUrl : avatarPlaceholder;
});
</script>

<template>
  <div class="profile-header-wrapper">
    <div :class="['content', { '--group-profile': isGroupProfile }]">
      <div class="header-container">
        <div class="avatar-image-container">
          <VSkeletonLoader
            v-if="isLoading"
            class="avatar-loader"
            type="avatar"
          />
          <AvatarImage
            v-else
            class="profile-image"
            :image-url="avatarImage"
            :alt="name"
          />
        </div>
        <slot name="upperDetail" />
      </div>
      <div class="profile-info-container">
        <VSkeletonLoader
          v-if="isLoading"
          width="240"
          type="heading"
        />
        <h1
          v-else
          class="name text-display-sm text-semibold"
        >
          {{ name }}
        </h1>
        <div class="details">
          <slot name="lowerDetail" />
        </div>
      </div>
    </div>
    <div
      v-if="isGroupProfile"
      class="cover-image"
    >
      <div :class="['image', { 'loading': isLoading }]">
        <ImageSkeletonLoader
          v-if="isLoading"
          :height="isMobile ? '144' : '336'"
        />
        <img
          v-else
          :src="coverImage"
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-header-wrapper {
  display: flex;
  flex-direction: column-reverse;
  padding: rem(48) 0;

  .cover-image {
    width: 100%;
    margin-bottom: rem(-40);

    .image.loading,
    :deep(.v-skeleton-loader__image) {
      @include border-radius-none;
    }

    .image:not(.loading) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      color: colors-get(gray, 500);
      background-color: colors-get(gray, 200);

      img {
        width: 100%;
        aspect-ratio: 3 / 1;
        object-fit: cover;
        object-position: center;
      }

      @include media-query-max(mobile) {
        text-align: center;
        padding: 0;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacings-get(6);
    width: 100%;
    padding: 0 spacings-get(4);
    text-align: center;
    z-index: 1;

    @include media-query-max(mobile) {
      padding: 0 spacings-get(4);
    }

    .header-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .profile-image {
      width: rem(160);
      height: rem(160);
      border-radius: 50%;
      padding: spacings-get(1);
      background: colors-get(base, white);
      box-shadow:
        0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);

      @include media-query-max(mobile) {
        width: rem(96);
        height: rem(96);
      }
    }

    .profile-info-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: spacings-get(2);
      align-items: center;

      .name {
        color: colors-get(gray, 900);
      }

      .details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: spacings-get(6);
        max-width: rem(800);
        margin: 0 auto;
      }
    }

    &.--group-profile {
      display: flex;
      align-items: flex-start;

      .header-container {
        display: flex;
        justify-content: space-between;
      }

      .profile-info-container {
        display: flex;
        align-items: flex-start;
      }

      .details {
        display: flex;
        align-items: flex-start;
        margin: 0;
      }
    }
  }
}

.avatar-loader {
  @include border-radius-rounded;

  :deep(.v-skeleton-loader__avatar) {
    width: rem(160);
    height: rem(160);
    max-width: none;
    max-height: none;
    margin: rem(1);

    @include media-query-max(mobile) {
      width: rem(96);
      height: rem(96);
    }
  }
}

:deep(.v-skeleton-loader__heading) {
  margin: 0;
}
</style>
