<script setup lang="ts">
defineProps({
	actionCount: {
		type: Number,
		default: 2,
	},
	showSocials: {
		type: Boolean,
		default: false,
	},
});

const { isMobile } = useWindowResize();
</script>
<template>
  <div class="loader-profile-details">
    <ParagraphSkeletonLoader :text-align="ALIGNMENT.CENTER" />
    <template v-if="showSocials">
      <div class="loader-buttons-wrapper">
        <ButtonSkeletonLoader
          v-for="item in 3"
          :key="item"
          class="loader-social-button"
          width="20"
          height="20"
        />
      </div>
    </template>
    <div class="loader-buttons-wrapper">
      <ButtonSkeletonLoader
        v-for="item in actionCount"
        :key="item"
        :width="isMobile ? '38': '120'"
        height="38"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.loader-profile-details {
	display: grid;
	gap: spacings-get(4);

	.loader-buttons-wrapper {
		display: flex;
		gap: spacings-get(4);
		justify-content: center;

		.loader-social-button {
			:deep(.v-skeleton-loader__bone) {
				@include border-radius-default;
			}
		}
	}

	.loader-following {
		margin: auto
	}
}
</style>