export enum STRIPE_CONNECT_ERROR_MESSAGE {
	MISSING_CONNECT_ACCOUNT = 'missing_connect_account',
	HAS_ACTIVE_EVENTS = 'has_active_events',
}

export enum EVENT_ERROR_MESSAGE {
	FAILED = 'reCAPTCHA verification failed. You might be a bot.',
}

export enum COMMON_ERROR_MESSAGE {
	TRY_AGAIN = 'Something went wrong. Please try again.',
	REFRESH_PAGE = 'Something went wrong. Please try refresh the page.',
}