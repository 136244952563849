import type { FilterInfo, PaymentCard } from '@/types/common';
import type {
	FormattedUserProfile,
	UserProfileResponse,
	UpdatedProfileInfo,
	FollowerListResponse,
	SocialProfile,
	FollowingListResponse,
	CountryTimezoneRequest,
	CardListResponse,
	TicketPurchasesHistoryResponse,
	AddCreditCardResponse,
	FollowingFitlerInfo,
} from '@/types/profile';
import type { UserNotificationSetting } from '@/types/user';
import { cloneDeep } from 'lodash';

interface ProfileStore {
	profileData: UserProfileResponse | null
	follower: {
		data: FollowerListResponse | null
		errorMessage: string
		isLoading: boolean
	}
	following: {
		data: FollowingListResponse | null
		errorMessage: string
	}
	social: {
		data: SocialProfile[]
		errorMessage: string
	}
	card: {
		data: CardListResponse | null
		stripeData: AddCreditCardResponse | null
		errorMessage: string
		isLoading: boolean
	}
	notificationSettingResponse: {
		data: UserNotificationSetting | null
		errorMessage: string
	}
	ticketPurchaseHistory: {
		data: TicketPurchasesHistoryResponse | null
		errorMessage: string
	}
	errorUpdateEmailMessage: string
	errorMessage: string
	isLoading: boolean
}

const useProfileStore = defineStore('profile', {

	state: (): ProfileStore => ({
		profileData: null,
		follower: {
			data: null,
			errorMessage: '',
			isLoading: false,
		},
		following: {
			data: null,
			errorMessage: '',
		},
		social: {
			data: [],
			errorMessage: '',
		},
		card: {
			data: null,
			stripeData: null,
			errorMessage: '',
			isLoading: false,
		},
		notificationSettingResponse: {
			data: null,
			errorMessage: '',
		},
		ticketPurchaseHistory: {
			data: null,
			errorMessage: '',
		},
		errorUpdateEmailMessage: '',
		errorMessage: '',
		isLoading: false,
	}),

	getters: {
		profile: (state: ProfileStore) => {
			if (!state.profileData) {
				return null;
			}
			return transformUserProfile(state.profileData);
		},

		profileId: (state: ProfileStore) => {
			if (!state.profileData) {
				return null;
			}
			return state.profileData.id;
		},

		socialInfo: (state: ProfileStore) => {
			if (!state.social.data) {
				return null;
			}
			return transformSocialInfo(state.social.data);
		},

		socialLink: (state: ProfileStore) => {
			if (!state.social.data) {
				return [];
			}
			return transformSocialLink(state.social.data);
		},

		notificationSetting: (state: ProfileStore): UserNotificationSetting => {
			return {
				sales: !!state.notificationSettingResponse.data?.sales,
				offer: !!state.notificationSettingResponse.data?.offer,
				recommendation: !!state.notificationSettingResponse.data?.recommendation,
			};
		},

		cardList: (state: ProfileStore): PaymentCard[] => {
			if (!state.card.data) {
				return [];
			}
			return transformCardList(state.card.data);
		},

		followingList: (state: ProfileStore) => {
			if (!state.following.data) {
				return [];
			}
			return transformFollowingList(state.following.data.followingGroups);
		},

		ticketPurchaseHistoryList: (state: ProfileStore) => {
			if (!state.ticketPurchaseHistory?.data) {
				return [];
			}
			return transformTicketPurchaseHistoryList(state.ticketPurchaseHistory.data);
		},

		stripeAddCardKeys: (state: ProfileStore) => {
			if (!state.card?.stripeData) {
				return null;
			}
			return {
				publicKey: state.card.stripeData.paymentSecrets.publicKey,
				clientSecret: state.card.stripeData.paymentSecrets.clientSecret,
				setupIntentsId: state.card.stripeData.setupIntentsId,
			};
		},
	},

	actions: {
		async myAccountInfo() {
			this.isLoading = true;
			this.errorMessage = '';

			const { data: result, errorMessage } = await getMyAccount();

			if (errorMessage.value) {
				// TODO: Get message from API
				this.errorMessage = 'The sign in code you entered is incorrect. Please try again.';
			}

			if (result.value) {
				this.profileData = result.value;
			}
			this.isLoading = false;
		},

		updateMyAccountVerificationStatus(status: boolean) {
			const cloneProfileData = cloneDeep(this.profileData);
			if (cloneProfileData) {
				this.profileData = {
					...cloneProfileData,
					verificationStep: status ? 1 : 0,
				};
			}
		},

		async updateProfile(profileInfo: UpdatedProfileInfo) {
			this.isLoading = true;
			this.errorMessage = '';

			const { data: result, errorMessage } = await patchUpdateProfileInfo(profileInfo);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.profileData = result.value;
			}
			this.isLoading = false;
		},

		async uploadProfileImage(file: File | Blob) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await postUploadProfileImageAPI(file);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async deleteProfileImage() {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await deleteProfileImageAPI();

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
			this.isLoading = false;
		},

		async setNotificationSetting(profile: FormattedUserProfile) {
			this.notificationSettingResponse.errorMessage = '';

			// Update default notification settings for peaz's user
			const { data: notificationData, errorMessage: notificationErrorMessage } = await postNotificationSettingAPI(profile.id, {
				sales: true,
				offer: false,
				recommendation: false,
			});

			if (notificationErrorMessage.value) {
				this.notificationSettingResponse.errorMessage = notificationErrorMessage.value;
			}

			if (notificationData.value) {
				this.notificationSettingResponse.data = notificationData.value;
			}
		},

		async initializeProfileGroup(profile: FormattedUserProfile) {
			this.isLoading = true;
			this.errorMessage = '';

			const { trackEvent } = useGtm();
			const profileStore = useProfileStore();
			const groupStore = useGroupStore();
			const authStore = useAuthStore();

			// Get a Peasy group, if it exists or not
			await groupStore.getGroupsByUserId(profile.id);

			// If Peasy group does exists
			if (groupStore.hasPeasyGroup) {
				this.isLoading = false;

				if (authStore.isAuthenticating && !authStore.errorMessage) {
					if (authStore.snsConnectMetaData) {
						trackEvent({
							user_id: profileStore.profileId,
							sns_method: authStore.authMethod === GTM_AUTH_METHOD_PARAMS.GOOGLE
								? GTM_CONNECTING_SNS_METHOD_PARAMS.GOOGLE
								: GTM_CONNECTING_SNS_METHOD_PARAMS.APPLE,
							event: authStore.snsConnectErrorMessage
								? GTM_EVENT_NAME.SNS_CONNECT_FAILURE
								: GTM_EVENT_NAME.SNS_CONNECT_SUCCESS,
						});
					}

					trackEvent({
						user_id: profileStore.profileId,
						sign_in_method: authStore.authMethod,
						event: GTM_EVENT_NAME.USER_SIGN_IN_SUCCESS,
					});
				}
				authStore.isAuthenticating = false;
				return;
			}

			// If Peasy group does NOT exist, create a new one
			try {
				await groupStore.createGroup(profile.name);

				trackEvent({
					user_id: profileStore.profileId,
					register_method: authStore.authMethod,
					event: GTM_EVENT_NAME.USER_REGISTER_SUCCESS,
				});
			} catch {
				trackEvent({
					register_method: authStore.authMethod,
					event: GTM_EVENT_NAME.USER_REGISTER_FAILURE,
				});
			}

			// Set initial notification setting
			await this.setNotificationSetting(profile);

			this.isLoading = false;
		},

		removeProfile() {
			this.profileData = null;
		},

		async getFollowerList(userGroupId: number, filter: FilterInfo) {
			this.follower.isLoading = true;
			this.follower.data = null;

			const { data, errorMessage } = await getFollowerListByUserId(userGroupId, filter);

			if (errorMessage.value) {
				this.follower.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.follower.data = data.value;
			}
			this.follower.isLoading = false;
		},

		async getFollowingList(
			userId: number,
			filter: FollowingFitlerInfo,
		) {
			this.isLoading = true;

			const { data, errorMessage } = await getFollowingListByUserId(userId, {
				...filter,
				sortBy: filter.sortBy ?? FOLLOWING_SORTING_METHOD.ALPHABETICAL_A2Z,
				itemPerPage: filter.itemPerPage ?? PAGINATION_CONFIG.ITEM_PER_PAGE,
			});

			if (errorMessage.value) {
				this.following.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.following.data = data.value;
			}
			this.isLoading = false;
		},

		async getSocialProfile(userId: number) {
			this.isLoading = true;
			this.social.data = [];

			const { data, errorMessage } = await getSocialProfileByUserId(userId);

			if (errorMessage.value) {
				this.social.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.social.data = data.value.data;
			}
			this.isLoading = false;
		},

		async updateSocialProfile(userId: number, socialProfile: SocialProfile[]) {
			this.isLoading = true;

			const { data: result, errorMessage } = await putUpdateSocialProfile(
				userId,
				socialProfile,
			);

			if (errorMessage.value) {
				this.social.errorMessage = errorMessage.value;
			}

			if (result.value) {
				this.social.data = result.value.data;
			}
			this.isLoading = false;
		},


		async getNotificationSetting(userId: number) {
			this.isLoading = true;
			this.notificationSettingResponse.errorMessage = '';

			const { data, errorMessage } = await getNotificationSettingAPI(userId);

			if (errorMessage.value) {
				this.notificationSettingResponse.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.notificationSettingResponse.data = data.value;
			}
			this.isLoading = false;
		},

		async updateNotificationSetting(userId: number, payload: UserNotificationSetting) {
			this.isLoading = true;
			this.notificationSettingResponse.errorMessage = '';

			const { data, errorMessage } = await postNotificationSettingAPI(userId, payload);

			if (errorMessage.value) {
				this.notificationSettingResponse.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.notificationSettingResponse.data = data.value;
			}
			this.isLoading = false;
		},

		async updateProfileEmail(userId: number, email: string, identityToken: string) {
			this.isLoading = true;
			this.errorUpdateEmailMessage = '';

			const { errorMessage } = await patchUpdateProfileEmail(
				userId,
				email,
				identityToken,
			);

			if (errorMessage.value) {
				this.errorUpdateEmailMessage = 'This email address has already been registered. Please try again with another email address.';
			}
			this.isLoading = false;
		},

		async updateProfileCountryTimezone(userId: number, payload: CountryTimezoneRequest) {
			this.isLoading = true;
			this.errorMessage = '';

			const { errorMessage } = await patchUpdateProfileCountryTimezone(
				userId,
				payload,
			);

			if (errorMessage.value) {
				this.errorMessage = errorMessage.value;
			}
		},

		async getCardList(
			userId: number,
			filter: FilterInfo,
		) {
			this.card.isLoading = true;
			this.card.errorMessage = '';

			const { data, errorMessage } = await getCardListByUserId(
				userId,
				{
					...filter,
					sortBy: filter.sortBy ?? SAVED_CARD_SORTING_METHOD.LATEST_CREATED,
					itemPerPage: filter.itemPerPage ?? PAGINATION_CONFIG.ITEM_PER_PAGE,
				},
			);

			if (errorMessage.value) {
				this.card.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.card.data = data.value;
			}
			this.card.isLoading = false;
		},

		async deleteCard(userId: number, cardId: string) {
			this.card.isLoading = true;
			this.card.errorMessage = '';

			const { errorMessage } = await deleteCardById(
				userId,
				cardId,
			);

			if (errorMessage.value) {
				this.card.errorMessage = errorMessage.value;
			}
			this.card.isLoading = false;
		},

		async addCard() {
			this.isLoading = true;
			this.card.errorMessage = '';

			const { data, errorMessage } = await postAddCreditCard();

			if (errorMessage.value) {
				this.card.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.card.stripeData = data.value;
			}
			this.isLoading = false;
		},

		async getTicketPurchaseHistoryList(filter: FilterInfo) {
			this.isLoading = true;

			const { data, errorMessage } = await getTicketPurchasesHistory(filter);

			if (errorMessage.value) {
				this.ticketPurchaseHistory.errorMessage = errorMessage.value;
			}

			if (data.value) {
				this.ticketPurchaseHistory.data = data.value;
			}
			this.isLoading = false;
		},
	},
});

export default useProfileStore;
