export const SING_IN_ENDPOINT = '/signin';
export const USERS_ENDPOINT = `/users`;
export const MFA_ENDPOINT = '/mfa';
export const USER_CHALLENGE_ENDPOINT = `${MFA_ENDPOINT}/challenge`;
export const USER_VERIFICATION_ENDPOINT = `${MFA_ENDPOINT}/verification`;
export const USER_REGISTRATION_PASSWORD_LESS = '/users-registration/passwordless';
export const USER_GROUPS = '/groups';
export const ACCOUNT_ENDPOINT = '/account';
export const CONTENT_GENERATION_ENDPOINT = '/ai/content-generation';
export const EVENT_ENDPOINT = '/events';
export const FORM_ENDPOINT = '/forms';
export const CHECK_IN_ENDPOINT = '/checkin';
export const ATTENDEE_ENDPOINT = '/attendees';
export const MESSAGE_ENDPOINT = '/message';
export const ORDER_ENDPOINT = '/orders';
export const COUNTRIES_ENDPOINT = '/countries';
export const CHECK_OUTS_ORDERS_ENDPOINT = '/checkouts/orders';
export const SNS_CONNECT_ENDPOINT = '/account/sns-connect';
export const EVENTS_SEARCH_ENDPOINT = '/events/search';
