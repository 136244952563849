export function useSnsConnect(
	callbackAppleSignInSuccess: () => Promise<void>,
	callbackAppleSignFailed: (response: any) => Promise<void>,
	callbackGoogleSignInSuccess: (response: google.accounts.oauth2.CodeResponse) => void,
) {
	const appleSignIn = ref<HTMLElement | null>(null);
	const googleClient = ref<google.accounts.oauth2.CodeClient>();
	const notificationStore = useNotificationStore();

	const initializeGoogleClient = () => {
		if (typeof google?.accounts?.oauth2 !== 'undefined') {
			googleClient.value = google.accounts.oauth2.initCodeClient({
				client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
				scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
				ux_mode: 'popup',
				callback: callbackGoogleSignInSuccess,
			});
		} else {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		}
	};

	const initializeAppleSignIn = () => {
		if (typeof AppleID !== 'undefined') {
			// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3235722
			AppleID.auth.init({
				clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
				redirectURI: import.meta.env.VITE_APPLE_REDIRECT_URI,
				scope: 'name email',
				usePopup: true,
			});
		} else {
			notificationStore.showErrorNotification(COMMON_ERROR_MESSAGE.REFRESH_PAGE);
		}
	};

	onMounted(() => {
		// Initialize Google client.
		initializeGoogleClient();

		// Get apple sign in button
		appleSignIn.value = document.getElementById('appleid-signin');

		// Initialize Apple
		initializeAppleSignIn();

		// Listen for authorization fail.
		document.addEventListener('AppleIDSignInOnFailure', callbackAppleSignFailed);

		// Listen for authorization success.
		document.addEventListener('AppleIDSignInOnSuccess', callbackAppleSignInSuccess);
	});

	onUnmounted(() => {
		document.removeEventListener('AppleIDSignInOnSuccess', callbackAppleSignInSuccess);
		document.removeEventListener('AppleIDSignInOnFailure', callbackAppleSignFailed);
	});

	return {
		appleSignIn,
		googleClient,
	};
}
