<script setup lang="ts">
import type { AlignmentType } from '@/types/common';

const props = defineProps({
	width: {
		type: String,
		default: '',
	},
	loading: {
		type: Boolean,
		default: false,
	},
	textAlign: {
		type: String as PropType<AlignmentType>,
		default: ALIGNMENT.START,
	},
});

const slots = defineSlots();

const hasSlotContent = computed(() => !!slots.default);
const classes = computed(() => {
	if (props.textAlign) {
		return `align-${props.textAlign}`;
	}
	return '';
});
</script>

<template>
  <template v-if="hasSlotContent">
    <VSkeletonLoader
      :class="classes"
      type="sentences"
      :loading="loading"
      :width="width"
    >
      <slot />
    </VSkeletonLoader>
  </template>
  <template v-else>
    <VSkeletonLoader
      :class="classes"
      type="sentences"
      :loading="loading"
      :width="width"
    />
  </template>
</template>

<style scoped lang="scss">
.align {
  &-start {
    :deep(.v-skeleton-loader__sentences) {
      justify-content: start;
    }
  }
  &-center {
    :deep(.v-skeleton-loader__sentences) {
      justify-content: center;
    }
  }
  &-end {
    :deep(.v-skeleton-loader__sentences) {
      justify-content: end;
    }
  }
}

:deep(.v-skeleton-loader__sentences) {
  gap: spacings-get(4);

  .v-skeleton-loader__bone {
    margin: 0;
  }
}
</style>
