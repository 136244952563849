<script setup lang="ts">
import type { Group } from '@/types/group';
import type { Profile, Social, SocialProfile } from '@/types/profile';

const props = defineProps({
	tab: {
		type: String,
		default: null,
	},
	eventId: {
		type: String || Number,
		default: null,
	},
});

const route = useRoute();
const router = useRouter();
const profileStore = useProfileStore();
const groupStore = useGroupStore();
const notificationStore = useNotificationStore();
const { isMobile } = useWindowResize();
const { trackEvent } = useGtm();

const tabs = [
	{
		title: 'Events attending',
		name: MY_ACCOUNT_TABS.ATTENDING,
	},
	{
		title: 'Groups hosting',
		name: MY_ACCOUNT_TABS.HOSTING,
	},
];

const activeTab = ref<string>(props.tab || MY_ACCOUNT_TABS.ATTENDING);
const isShowEditDialog = ref(false);
const isShowGroupCreationDialog = ref(false);
const isShowMessageDialog = ref(false);
const isShowNoFollowerDialog = ref(false);
const myAccountAttendingRef = ref();
const groupListRef = ref();
const senderGroupId = ref();

const profile = reactive<Partial<Profile>>({
	profileName: '',
	profileDescription: '',
	profileImageUrl: '',
});

const profileId = computed(() => profileStore.profileId);
const isLoading = computed(() => profileStore.isLoading);
const socialLink = computed(() => profileStore.socialLink);
const isShowAttendingTab = computed(() => profileId.value && activeTab.value === MY_ACCOUNT_TABS.ATTENDING);

const sharedProfileUrl = computed(() => {
	return getFullUrlPath(`/user/${profileId.value}`);
});

function generateSocialPayload(data: Social): SocialProfile[] {
	return Object.entries(data).map(([sns, url]) => {
		return {
			sns,
			profileUrl: url,
		};
	});
}

async function handleUpdateProfile(data: Profile) {
	const { hasDeletedImage, profileImageFile, ...newData } = data;

	// Check for profile name or description changes
	if (
		newData.profileDescription !== profile.profileDescription ||
		newData.profileName !== profile.profileName
	) {
		await profileStore.updateProfile({
			name: newData.profileName,
			...((newData.profileDescription || newData.profileDescription === '') ? { description: newData.profileDescription || ' ' } : {}),
		});
	}

	// Check for social changes
	if (profileId.value && data.socials) {
		const socialPayload = generateSocialPayload(data.socials);
		await profileStore.updateSocialProfile(profileId.value, socialPayload);
	}

	// Check for group cover image changes
	if (profileImageFile && !hasDeletedImage) {
		await profileStore.uploadProfileImage(profileImageFile);
	} else if (hasDeletedImage) {
		await profileStore.deleteProfileImage();
	}

	if (profileStore.errorMessage) {
		notificationStore.showErrorNotification(profileStore.errorMessage);
	} else if (profileStore.social.errorMessage) {
		notificationStore.showErrorNotification(profileStore.social.errorMessage);
	} else {
		notificationStore.showSuccessNotification('Successfully updated your profile.');
		await profileStore.myAccountInfo();

		trackEvent({
			user_id: profileStore.profileId,
			group_id: groupStore.primaryGroup?.id, // TODO: Rethinking about this parameter
			event: GTM_EVENT_NAME.EDIT_USER_PROFILE_SUCCESS,
		});
	}
	isShowEditDialog.value = false;
}


async function handleUploadGroupImage(id: number, data: Group) {
	const { groupCoverImageFile, groupImageFile, isDeletedLogoImage, isDeletedCoverImage } = data;
	const hasDeletedImage = isDeletedLogoImage || isDeletedCoverImage;

	// Check for group cover and logo image changes
	if (groupCoverImageFile && groupImageFile && !hasDeletedImage) {
		// Upload both cover image and logo image
		await groupStore.uploadGroupImage(id, {
			logo: groupImageFile,
			cover: groupCoverImageFile,
		}, [GROUP_IMAGE_FIELD.LOGO, GROUP_IMAGE_FIELD.COVER_IMAGE]);
	} else if (groupCoverImageFile && !hasDeletedImage) {
		// Upload only cover image
		await groupStore.uploadGroupImage(id, { cover: groupCoverImageFile }, [GROUP_IMAGE_FIELD.COVER_IMAGE]);
	} else if (groupImageFile && !hasDeletedImage) {
		// Upload only logo image
		await groupStore.uploadGroupImage(id, { logo: groupImageFile }, [GROUP_IMAGE_FIELD.LOGO]);
	}
}

async function handleCreateGroup(data: Group) {
	const { groupName, groupDescription } = data;

	if (groupName) {
		await groupStore.createGroup(groupName, groupDescription);
	}

	if (groupStore.errorMessage) {
		notificationStore.showErrorNotification(groupStore.errorMessage);
		isShowGroupCreationDialog.value = false;
		return;
	}

	if (groupStore.createdGroupId) {
		await handleUploadGroupImage(groupStore.createdGroupId, data);
	}

	if (profileStore.errorMessage) {
		notificationStore.showErrorNotification(groupStore.errorMessage);
	} else {
		notificationStore.showSuccessNotification('Successfully created group.');
		isShowGroupCreationDialog.value = false;

		const origin = window?.location.origin;
		const resolvedRoute = router.resolve({
			name: ROUTE.GROUP.NAME,
			params: {
				id: groupStore.createdGroupId,
			},
		});

		openLink(`${origin}${resolvedRoute.href}`);
		groupListRef.value.fetchGroupList();
	}
}

function handleOpenGroupCreationDlg() {
	isShowGroupCreationDialog.value = true;
}


function handleOnDialogClose() {
	isShowMessageDialog.value = false;
}

function handleShowMessageDialog(data: { id: number, followerNum: number }) {
	if (data.followerNum) {
		isShowMessageDialog.value = true;
	} else {
		isShowNoFollowerDialog.value = true;
	}

	senderGroupId.value = data.id;
}

async function handleOnSendMessage(subject: string, body: string) {
	if (!senderGroupId.value) {
		isShowMessageDialog.value = false;
		return;
	}

	await groupStore.sendMessageToGroupFollowers(
		senderGroupId.value,
		{
			body,
			subject,
		},
	);

	if (groupStore.errorMessage) {
		notificationStore.showErrorNotification(groupStore.errorMessage);
	} else {
		notificationStore.showSuccessNotification('Successfully sent the message.');
		trackEvent({
			user_id: profileId.value,
			group_id: senderGroupId.value,
			message_sending_method: GTM_MESSAGE_SENDING_METHOD_PARAMS.GROUP_FOLLOWERS,
			event: GTM_EVENT_NAME.MESSAGE_SENT,
		});
	}

	senderGroupId.value = null;
	isShowMessageDialog.value = false;
}

watch(() => props.tab, (newTab) => {
	if (newTab) {
		activeTab.value = newTab.toString();
	}
});

// Watch for changes in the profile data and update profile form
watch(() => profileStore.profile, async (newData) => {
	if (newData) {
		profile.profileName = newData.name;
		profile.profileDescription = newData.description;
		profile.profileImageUrl = newData.avatarLargeUrl;

		// Fetch social media profile
		await profileStore.getSocialProfile(newData.id);
	}
});

onMounted(async () => {
	// Open view ticket detail
	const { eventId } = route.query;
	if (eventId) {
		// Remove query string
		router.push({ query: {} });
		// Open view ticket detail on sidebar
		myAccountAttendingRef.value.viewTicketDetail(Number(eventId));
	}

	if (profileId.value) {
		await profileStore.getSocialProfile(profileId.value);
	}

	if (profileStore.profile) {
		profile.profileName = profileStore.profile.name;
		profile.profileImageUrl = profileStore.profile.avatarLargeUrl;
	}

	trackEvent({
		user_id: profileStore.profileId,
		// group_id: groupStore.primaryGroup?.id, // TODO: Rethinking about this parameter
		event: GTM_EVENT_NAME.VIEW_MY_TICKETS_SUCCESS,
	});
});
</script>

<template>
  <div class="profile-page-container page">
    <UserProfileHeader
      :name="profileStore.profile?.name"
      :profile-image-url="profileStore.profile?.avatarLargeUrl"
      :is-loading="isLoading"
    >
      <template #lowerDetail>
        <UserProfileInfoSkeletonLoader
          v-if="isLoading"
          :action-count="2"
          show-socials
        />
        <template v-else>
          <p
            v-if="profileStore.profile?.description"
            class="text-md text-regular"
          >
            {{ profileStore.profile.description }}
          </p>
          <SocialList :links="socialLink" />
          <div class="action-container">
            <BaseButton
              variant="outlined"
              color="gray"
              size="sm"
              :disabled="isLoading"
              @click="isShowEditDialog = true"
            >
              <template #leftIcon>
                <EditIcon />
              </template>
              <p v-if="!isMobile">
                Edit profile
              </p>
            </BaseButton>
            <ShareButton
              v-if="profileId"
              :url="sharedProfileUrl"
              :user-id="profileId"
            >
              <p v-if="!isMobile">
                Share
              </p>
            </ShareButton>
          </div>
        </template>
      </template>
    </UserProfileHeader>

    <div class="tabs">
      <VTabs
        v-model="activeTab"
        centered
        grow
        height="32px"
      >
        <VTab
          v-for="tabItem in tabs"
          :key="tabItem.name"
          :value="tabItem.name"
          :ripple="false"
        >
          {{ tabItem.title }}
        </VTab>
      </VTabs>
      <VWindow v-model="activeTab">
        <VWindowItem :value="MY_ACCOUNT_TABS.ATTENDING">
          <MyAccountAttending
            v-if="isShowAttendingTab"
            ref="myAccountAttendingRef"
            :user-id="profileId"
            :disabled="isLoading"
            :is-profile-loading="isLoading"
          />
        </VWindowItem>
        <VWindowItem :value="MY_ACCOUNT_TABS.HOSTING">
          <GroupList
            v-if="activeTab === MY_ACCOUNT_TABS.HOSTING"
            ref="groupListRef"
            :profile-id="profileId"
            :is-loading="isLoading"
            is-private-view
            @on-open-group-creation-dlg="handleOpenGroupCreationDlg"
            @on-open-message-dlg="handleShowMessageDialog"
          />
        </VWindowItem>
      </VWindow>
    </div>

    <SendMessageDialog
      receiver="All followers"
      :is-show="isShowMessageDialog"
      :disabled="isLoading"
      @on-close="handleOnDialogClose"
      @on-send="handleOnSendMessage"
    />
    <ConfirmDialog
      :is-show="isShowNoFollowerDialog"
      confirm-button-name="Ok, I got it"
      hide-cancel-button
      @on-close="isShowNoFollowerDialog = false"
      @on-confirm="isShowNoFollowerDialog = false"
    >
      <template #header>
        <InfoCircleIcon
          class="icon-header-confirm-dlg icon-info"
          :color="ICON_COLOR.PRIMARY"
        />
      </template>
      <template #title>
        No followers at the moment
      </template>
      <template #content>
        <p class="text-sm text-regular">
          You currently do not have any followers to send your message to.
        </p>
      </template>
    </ConfirmDialog>
    <ProfileDialog
      :is-show="isShowEditDialog"
      :disabled="profileStore.isLoading"
      @on-close="isShowEditDialog = false"
      @on-update="handleUpdateProfile"
    />
    <GroupDialog
      :is-show="isShowGroupCreationDialog"
      :disabled="profileStore.isLoading"
      is-create-mode
      @on-close="isShowGroupCreationDialog = false"
      @on-update="handleCreateGroup"
    />
  </div>
</template>
<style lang="scss" scoped>
.profile-page-container {
	margin: auto;
	padding-top: 0;
}

.action-container {
	display: flex;
	gap: spacings-get(4);
	margin: auto;

	@include media-query-max(mobile) {
		button {
			padding: spacings-get(2);
		}
	}
}

.tabs {
	@include media-query-max(mobile) {
		padding: 0 spacings-get(4);
	}
}
</style>