<script setup lang="ts">
import type { ButtonGroupItem } from '@/types/button-group';
import type { EventInfo } from '@/types/event';

const props = defineProps({
	userId: {
		type: Number as PropType<number | null>,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	isProfileLoading: {
		type: Boolean,
		default: false,
	},
});

const userStore = useUserStore();
const profileStore = useProfileStore();
const { trackEvent } = useGtm();

const filterItem: ButtonGroupItem[] = [
	{
		name: 'Upcoming',
		value: USER_EVENT_ATTENDING_FILTER.UPCOMING,
	},
	{
		name: 'Archived',
		value: USER_EVENT_ATTENDING_FILTER.ARCHIVED,
	},
];

const selectedFilter = ref<string>(filterItem[0].value);
const currentPage = ref(1);
const showSidebar = ref(false);
const items = ref<EventInfo[]>([]);

const profileId = computed(() => props.userId);
const isLoading = computed(() => userStore.userEventAttending.isLoading || props.isProfileLoading);
const totalPages = computed(() => userStore.userEventAttending.response?.paginationInfo.totalPages);

function handleSideDrawerClose() {
	showSidebar.value = false;
}

async function handleViewTicket(eventId: number) {
	if (!profileId.value) {
		return;
	}
	// No need to fetch new data for the same event id twice
	if (eventId !== userStore.ticketInfo?.event?.id) {
		await userStore.getTicketDetail(profileId.value, eventId);
		await userStore.checkTicketOwnership(eventId);

		trackEvent({
			user_id: profileStore.profileId,
			ticket_id: userStore.ticketIds,
			event: GTM_EVENT_NAME.VIEW_TICKET_SUCCESS,
		});
	}
	// TODO: Change to open side bar instantly
	// when implement skeletons on loading state
	showSidebar.value = true;
}

async function fetchData() {
	if (profileId.value) {
		await userStore.getUserAttendingEventById(profileId.value, {
			itemPerPage: 10,
			currentPage: currentPage.value,
			filter: selectedFilter.value,
			sortBy: selectedFilter.value === USER_EVENT_HOSTING_FILTER.UPCOMING
				? USER_EVENT_SORTING.SORT_ASC
				: USER_EVENT_SORTING.SORT_DESC,
		});
	}
}

function handleFilterChange() {
	currentPage.value = 1;
	items.value = [];
	fetchData();
}

function handlePageChange(page: number) {
	currentPage.value = page;
	fetchData();
}

onMounted(() => {
	fetchData();
});

watch(profileId, () => {
	fetchData();
});

watch(() => userStore.userEventAttendingList, (newUserEventAttendingList) => {
	items.value = newUserEventAttendingList;
});

defineExpose({
	viewTicketDetail: handleViewTicket,
});
</script>
<template>
  <div class="attending-list">
    <div class="header">
      <h1 class="text-lg text-semibold">
        Groups
      </h1>
      <BaseToggleButtonGroup
        v-model="selectedFilter"
        :items="filterItem"
        @update:model-value="handleFilterChange"
      />
    </div>
    <template v-if="items?.length">
      <div class="items">
        <EventDetailsItem
          v-for="item in items"
          :key="item.id"
          :data="item"
        >
          <template #eventStatus>
            <BaseChip v-if="item.isHappeningNow && item.status === EVENT_STATUS.OPEN">
              Happening now
            </BaseChip>
            <BaseChip
              v-else-if="item.status === EVENT_STATUS.CANCELED"
              :color="CHIP_COLOR.GRAY"
            >
              Canceled
            </BaseChip>
          </template>
          <template #action>
            <BaseButton
              class="btn-view-ticket"
              variant="outlined"
              color="gray"
              size="md"
              :disabled="disabled"
              icon-only
              @click="() => handleViewTicket(item.id)"
            >
              <template #leftIcon>
                <TicketIcon />
              </template>
              View ticket
            </BaseButton>
          </template>
        </EventDetailsItem>
      </div>
      <div class="paginate">
        <BasePagination
          :page="currentPage"
          :total-pages="totalPages"
          @on-page-change="handlePageChange"
        />
      </div>
    </template>
    <template v-else>
      <EventListLoader
        v-if="isLoading"
        button-width="136"
      />
      <NoDataFound v-else>
        <template #content>
          <h1 class="text-md text-semibold">
            No events found
          </h1>
          <p class="text-sm text-regular">
            Your events will be listed here once you’ve purchased tickets.
          </p>
        </template>
      </NoDataFound>
    </template>
  </div>
  <TicketDetailSideDrawer
    v-if="userStore.ticketInfo"
    :data="userStore.ticketInfo"
    :is-open="showSidebar"
    :has-ticket-ownership="userStore.hasTicketOwnership"
    @on-close="handleSideDrawerClose"
    @on-cancel="handleSideDrawerClose"
  />
</template>
<style lang="scss" scoped>
.attending-list {
  margin-top: spacings-get(4);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn-view-ticket {
    min-width: rem(136);
    margin: 0 spacings-get(10);

    @include media-query-max(mobile) {
        margin: 0;
        width: 100%;
    }
  }
}
</style>
